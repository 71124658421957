import React from 'react';
import BlogContainer from '../components/BlogContainer';
import BlogMain from '../components/BlogMain';
import BlogFeatureImage from '../components/BlogFeatureImage';
import BlogCard from '../components/BlogCard';
import { graphql } from 'gatsby';
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
} from '../libs/helpers';
import BlogPostPreviewList from '../templates/blog-post-preview-list';
import GraphQLErrorList from '../components/graphql-error-list';
import SEO from '../components/Seo';
import Layout from '../components/Layout';

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query IndexPageQuery {
    posts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          title
          excerpt
          slug {
            current
          }
        }
      }
    }
  }
`;

const Blog = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  // site Infos aus Sanity holen
  const site = (data || {}).site;
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  /*  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }  */

  return (
    <div>
      <SEO
        title="Blog"
        description="Blog description"
        keywords="blog, drum, schlagzeug, unterricht"
      />
      <BlogContainer>
        <BlogFeatureImage>Bild Blog Start</BlogFeatureImage>
        <BlogMain>
          <BlogCard>
            <h1 className="blogH1">Welcome to THE BLOG</h1>
            {postNodes && (
              <BlogPostPreviewList
                title="Latest blog posts"
                nodes={postNodes}
                browseMoreHref="/archive/"
              />
            )}
          </BlogCard>
        </BlogMain>
      </BlogContainer>
    </div>
  );
};

export default Blog;
